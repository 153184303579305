import { css } from '@emotion/css';
import { ClickAwayListener, Fade } from '@mui/material';
import { IC_EXPORT_TO_EXCEL_BUTTON, IC_FILTER_BUTTON, IC_FILTER_BUTTON_CLICKED, IC_TRASH2 } from '../../../../../../../Assets';
import { VolatilityTableData } from '../../../../../../../Models/API/Waterfall/IForm';
import Image from '../../../../../../../Shared/Components/Image';
import Spinner from '../../../../../../../Shared/Components/Spinner/Spinner';
import { Cell, Row, Table } from '../../../../../../../Shared/Components/Table/Table.Style';
import { getPercentrage, isNumber } from '../../../../../../../Shared/Utilities';
import appConfig from '../../../../../../../config/config';
import { useEffect, useState } from 'react';
import Select from '../../../../../../../Shared/Components/Select/Select';
import { NumberOption } from '../../../../../../../Models/API/All/NumberOption';

type Props = {
	data?: VolatilityTableData;
	setData?: (data: VolatilityTableData) => void;
};

const Style = css({
	marginTop: '4rem',
	'&__table': {
		border: `1px solid ${appConfig.style.colors.table}`,
		overflow: 'unset !important',
		maxHeight: 'unset !important',
		borderRadius: 16,
	},
	'&__header': {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '0.4rem',
		'.title': {
			fontWeight: 500,
		},
		'.actions': {
			display: 'flex',
			alignItems: 'center',
			position: 'relative',
			'.import-img': {
				marginTop: 3,
				marginRight: '1.2rem',
			},
			'.filter-container': {
				position: 'absolute',
				right: 0,
				top: 'calc(100% + 0.6rem)',
				zIndex: 10,
				background: '#ffffff',
				border: `1px solid ${appConfig.style.colors.background1}`,
				padding: '3rem',
				boxShadow: `3px 3px 3px 0px #C9CAD066`,
				borderRadius: 8,
				width: '36rem',
			},
		},
	},
});

const VolatilityTable = ({ data, setData }: Props) => {
	const [showFilter, setShowFilter] = useState<boolean>(false);
	const [filter, setFilter] = useState<{ from: number; to: number }>();

	useEffect(() => {
		if (!data || filter) return;
		setFilter({ from: data.adjustedTableFrom, to: data.adjustedTableChosenTo });
	}, [data]);

	if (!data || !filter) return <Spinner incorporated center />;

	const options: NumberOption[] = Array.from({ length: (filter.to - filter.from) / data.adjustedTableInterspace + 1 }).map((_, i) => ({
		label: (filter.from + i * data.adjustedTableInterspace).toString(),
		value: filter.from + i * data.adjustedTableInterspace,
	}));

	const toOptions: NumberOption[] = options.filter((opt) => {
		if (!isNumber(opt.value)) return true;
		return opt.value >= data.adjustedTableFrom;
	});

	return (
		<Fade in={data.volatilities.length > 2} unmountOnExit>
			<div className={Style}>
				<div className={`${Style}__header`}>
					<span className="title">Time to liquidity event: X</span>
					<div className="actions">
						<Image data-qaid="VolatilityTable.Button.Export" className="import-img pointer" src={IC_EXPORT_TO_EXCEL_BUTTON} />
						<Image
							data-qaid="VolatilityTable.Button.Filter"
							className="pointer"
							src={showFilter ? IC_FILTER_BUTTON_CLICKED : IC_FILTER_BUTTON}
							onClick={() => setShowFilter((prev) => !prev)}
						/>
						<Fade in={showFilter} unmountOnExit>
							{/* <ClickAwayListener onClickAway={() => setShowFilter(false)}> */}
							<div className="filter-container">
								<div className="bold">Volatility range in years</div>
								<Select
									qaid="VolatilityTable.Select.From"
									value={data.adjustedTableFrom}
									defaultValue="From"
									options={options}
									onChange={(value) => {
										if (!isNumber(value)) return;
										setData?.({ ...data, adjustedTableFrom: value });
									}}
								/>
								<Select
									qaid="VolatilityTable.Select.To"
									value={data.adjustedTableChosenTo}
									defaultValue="to"
									options={toOptions}
									onChange={(value) => {
										if (!isNumber(value)) return;
										setData?.({ ...data, adjustedTableChosenTo: value });
									}}
								/>
								<Select
									qaid="VolatilityTable.Select.Interspace"
									value={data.adjustedTableChosenTo}
									defaultValue="to"
									options={[
										{ value: data?.adjustedTableInterspace, label: data.adjustedTableInterspace?.toString() },
										{ value: 0.5, label: '0.5' },
									]}
									onChange={(value) => {
										if (!isNumber(value)) return;
										setData?.({ ...data, adjustedTableInterspace: value });
									}}
								/>
							</div>
							{/* </ClickAwayListener> */}
						</Fade>
					</div>
				</div>
				<Table className={`${Style}__table`}>
					<Row header headerSize={1.25}>
						<Cell border={{ bottom: true, right: true }} style={{ borderTopLeftRadius: 16 }}>
							Peer Company
						</Cell>
						{data.volatilities[0]?.data.map((obj) => (
							<Cell key={obj.expectedTermsInYears} border={{ bottom: true, right: true }}>
								{obj.expectedTermsInYears} {obj.expectedTermsInYears === 1 ? 'Year' : 'Years'}
							</Cell>
						))}
						<Cell border={{ bottom: true }} style={{ borderTopRightRadius: 16 }}>
							Actions
						</Cell>
					</Row>
					{data.volatilities.map((peer, idx) => {
						const isLast = idx === data.volatilities.length - 1;
						const isTotal = peer.companyName === 'median' || peer.companyName === 'average';
						return (
							<Row key={peer.identitifier} rowSize={1.25} isTotal={isTotal}>
								<Cell
									border={{ bottom: !isLast, right: true }}
									style={{ borderBottomLeftRadius: isLast ? 16 : 0, fontWeight: isTotal ? 500 : 400 }}
								>
									{isTotal ? `${peer.companyName[0].toUpperCase()}${peer.companyName.slice(1)}` : peer.companyName}
								</Cell>
								{data.volatilities[0]?.data.map((obj) => (
									<Cell key={obj.expectedTermsInYears} border={{ bottom: !isLast, right: true }} style={isTotal ? { fontWeight: 500 } : {}}>
										{getPercentrage(obj.volatility)}
									</Cell>
								))}
								<Cell border={{ bottom: !isLast }} style={{ borderBottomRightRadius: isLast ? 16 : 0, fontWeight: isTotal ? 500 : 400 }}>
									{!isTotal && (
										<Image
											src={IC_TRASH2}
											data-qaid="VolatilityTable.Button.Delete"
											className="pointer"
											width="2rem"
											tooltip="Delete"
											onClick={() => {
												setData?.({
													...data,
													volatilities: data.volatilities.filter((v) => v.identitifier !== peer.identitifier),
												});
											}}
										/>
									)}
								</Cell>
							</Row>
						);
					})}
				</Table>
			</div>
		</Fade>
	);
};

export default VolatilityTable;
