import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { IC_DOC_PDF, IC_DOWNLOAD_ACTION, IC_EYE_ACTION, IC_FILTER, IC_SIGN_ACTION } from '../../../Assets';
import { ElectronicSignature, ElectronicSignatureDoc, SignerDetails } from '../../../Models/API/Document/eletronics-signature';
import { SignatureStatus } from '../../../Models/API/Document/signature-status-enum';
import Button from '../../../Shared/Components/Button/Button';
import Clickable from '../../../Shared/Components/Clickable/Clickable';
import Image from '../../../Shared/Components/Image';
import IndicationStatus from '../../../Shared/Components/IndicationStatus';
import OverflowText from '../../../Shared/Components/OverflowText';
import Spinner from '../../../Shared/Components/Spinner/Spinner';
import Table, { TableColumn } from '../../../Shared/Components/Table/Table';
import useRootStore from '../../../Shared/Hooks/useRootStore';
import { formatDate, getMimeType, isArray, isNumber } from '../../../Shared/Utilities';
import appConfig from '../../../config/config';
import Tabs from '../Components/Tabs';
import useDocument from '../helpers/hooks/useDocument';
import Filter from './Filter';

const TableStye = css({
	'&__actions': {
		marginBottom: '2rem',
	},
	'.selected': {
		background: '#F9F9FF !important',
	},
	"div[data-cell-type='select']": {
		borderRight: `1px solid ${appConfig.style.colors.table}`,
	},
	"div[data-cell-type='header']:not(:last-child)": {
		borderRight: `1px solid ${appConfig.style.colors.table}`,
	},
	"div[data-type='filter-container']": {
		minWidth: '30rem',
	},
});

const Style = css({
	position: 'relative',
	'&__sign-btn': {
		position: 'absolute',
		bottom: 0,
		right: 0,
		width: '24rem',
	},
});

const Signatures = () => {
	const {
		documentsStore,
		contactStore,
		companyStore: { companyId },
		auth: { currentUserInfo },
	} = useRootStore();
	const [selected, setSelected] = useState<ElectronicSignature[]>([]);
	const { previewDocument, getDocument, signDocument, filteredDocs, setFilteredDocs } = useDocument();

	// const getDoc = (templateId: string, updateState: boolean = true, isPreview: boolean = false) => {
	//     return run(() => documentsStore.getDocument(templateId), {
	//         cacheKey: isPreview ? `preview-${templateId}` : templateId,
	//         updateState,
	//     });
	// };

	const onDownloadHandler = async (e: SyntheticEvent, templateId: string, fileName: string) => {
		e.stopPropagation();
		const res = await getDocument(templateId, false);
		const link = document.createElement('a');
		if (!res.data?.data.fileBase64) return;

		link.href = `data:${getMimeType('pdf')};base64,${res.data?.data.fileBase64}`;
		link.download = `${fileName}.pdf`;
		link.click();
	};

	const isPending = (signers: SignerDetails[]) => {
		return signers.some((signer) => signer.contactId === currentUserInfo.contactId && signer.status === SignatureStatus.SentForSignature);
	};

	const tableColumns: TableColumn<ElectronicSignature>[] = [
		{
			name: 'document',
			label: 'Title',
			sortable: true,
			render(obj, value: ElectronicSignatureDoc) {
				return (
					<>
						<Image width="4rem" src={IC_DOC_PDF} style={{ marginRight: '0.8rem' }} />
						<OverflowText>{value.documentName}</OverflowText>
					</>
				);
			},
			format: (_, obj) => obj?.document.documentName,
			isSearch: true,
		},
		{
			name: 'signers',
			label: 'Stakeholder',
			format: (signers: SignerDetails[]) => {
				const signer = signers.find((s) => s?.isDesignatedCompanySignatory === false);
				return isNumber(signer?.contactId) ? contactStore.getContactFullNameById(signer.contactId) : undefined;
			},
			sortable: true,
		},
		{
			name: 'type', // Daniel: to fix
			label: 'Status',
			sortable: true,
			render: (obj, value) => {
				const completed = obj.signers.reduce((acc, signer) => {
					const val = signer.status === SignatureStatus.Signed ? 1 : 0;
					return acc + val;
				}, 0);

				return (
					<IndicationStatus
						completed={completed}
						total={obj.signers.length}
						isCancelled={obj.isCancelled}
						completeText="All signed"
						isPending={isPending(obj.signers)}
						pendingText="Pending signature"
					/>
				);
			},
			format: (_, obj) => {
				const completed = obj?.signers.reduce((acc, signer) => {
					const val = signer.status === SignatureStatus.Signed ? 1 : 0;
					return acc + val;
				}, 0);
				return `${completed}/${obj?.signers.length}`;
			},
			style: { minWidth: '21rem' },
		},
		{
			name: 'createdByContactId', // Daniel: to fix
			label: 'Created by',
			sortable: true,
			format: (value) => contactStore.getContactFullNameById(value),
		},
		{
			name: 'createTimeStamp', // Daniel: to fix
			label: 'Date',
			sortable: true,
			format: (value) => formatDate(value),
			sortType: 'date',
		},
		{
			name: 'actions',
			label: 'Actions',
			style: { flex: '0 0 22rem', gap: '2.4rem' },
			render: (obj, value) => {
				const isPend = isPending(obj.signers);
				const isSignDisabled = isPend && selected.some((s) => s.document.id === obj.document.id);
				return (
					<>
						{isPend && !obj.isCancelled ? (
							<Clickable
								qaid=""
								onClick={(e) => {
									e.stopPropagation();
									!isSignDisabled && signDocument([obj]);
								}}
								flex="0 0 2rem"
								disabled={isSignDisabled}
							>
								<Image width="1.6rem" src={IC_SIGN_ACTION} tooltip="E-Sign" />
							</Clickable>
						) : (
							<Clickable
								qaid=""
								onClick={(e) => {
									e.stopPropagation();
									onDownloadHandler(e, obj.document.id, obj.document.documentName);
								}}
								flex="0 0 2rem"
							>
								<Image width="2rem" src={IC_DOWNLOAD_ACTION} tooltip="Download" />
							</Clickable>
						)}
						<Clickable
							qaid=""
							onClick={(e) => {
								e.stopPropagation();
								previewDocument(obj.document.id, '', obj.document.documentName);
							}}
							flex={0}
						>
							<Image width="2.5rem" src={IC_EYE_ACTION} tooltip="Preview" />
						</Clickable>
					</>
				);
			},
		},
	];

	if (!documentsStore.templates) {
		return <Spinner incorporated center />;
	}

	return (
		<div className={Style}>
			<Table
				columns={tableColumns}
				rows={filteredDocs}
				rowsPerPage={7}
				className={TableStye}
				border={{ bottom: true }}
				rowSize={2}
				headerSize={1.5}
				rowClassName={(row) => (selected.some((s) => s.document.id === row.document.id) ? 'selected' : undefined)}
				onRowSelect={(rows) => {
					setSelected(rows as ElectronicSignature[]);
				}}
				onSelectException={(obj) => !isPending(obj.signers) || obj.isCancelled}
				customHeaderRender={<Tabs />}
				exportToExcel={{ fileName: 'Signatures', sheetName: 'Signatures' }}
				customActionsRender={<Filter docs={documentsStore.docs} onFilter={(docs) => setFilteredDocs(docs)} />}
			/>
			{isArray(documentsStore.docs) && (
				<Button
					className={`${Style}__sign-btn`}
					label="Sign selected"
					qaid="Signatures.Button.Sign"
					width="22rem"
					cancel={selected.length === 0}
					disabled={selected.length === 0}
					onClick={() => {
						signDocument(selected);
					}}
				/>
			)}
		</div>
	);
};

export default observer(Signatures);
