import { css } from '@emotion/css';
import { Collapse } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { IC_LOOKUP } from '../../../../../../Assets';
import InputLabel from '../../../../../../config/style/components/InputLabel/InputLabel';
import { PeerSearchParams, VolatilityData, VolatilityTableData, WaterfallPeer } from '../../../../../../Models/API/Waterfall/IForm';
import { IndustryEnum } from '../../../../../../Models/App/Valuation/industry-enum';
import { SectorEnum } from '../../../../../../Models/App/Valuation/sector-enum';
import Button from '../../../../../../Shared/Components/Button/Button';
import ExpandToggle from '../../../../../../Shared/Components/ExpandToggle';
import Hr from '../../../../../../Shared/Components/HorizontalRule/Hr';
import AutoCompleteInput from '../../../../../../Shared/Components/Input/AutoCompleteInput';
import { TextAreaInput } from '../../../../../../Shared/Components/Input/Input.Style';
import Label from '../../../../../../Shared/Components/Layout/Label';
import OverflowText from '../../../../../../Shared/Components/OverflowText';
import Select from '../../../../../../Shared/Components/Select/Select';
import Tooltip from '../../../../../../Shared/Components/Tooltip';
import { ForwardedRef } from '../../../../../../Shared/Hooks/useMultiStepForm';
import useRootStore from '../../../../../../Shared/Hooks/useRootStore';
import { formatDate, formatDecimal, isNumber } from '../../../../../../Shared/Utilities';
import { WaterfallStep } from '../../../../../Waterfall/Components/AddEditWaterfall/index.style';
import WaterfallSubtitle from '../../../../../Waterfall/Components/WaterfallSubtitle/WaterfallSubtitle';
import { industryOptions, sectorOptions } from '../../../../utils';
import VolatilityStyle from './index.style';
import CheckBox from '../../../../../../Shared/Components/CheckBox/CheckBox';
import appConfig from '../../../../../../config/config';
import CircularSwitchToggle from '../../../../../../Shared/Components/CircularSwitchToggle/CircularSwitchToggle';
import VolatilityTable from './Tables/VolatilityTable';
import MultiplesTable from './Tables/MultiplesTable';

const Volatility = forwardRef<ForwardedRef, any>((_, forwardedRef) => {
	const { valuationStore, appState } = useRootStore();
	const [isListOpen, setIsListOpen] = useState<boolean>(false);
	const [selectedSector, setSelectedSector] = useState<SectorEnum>();
	const [selectedIndustry, setSelectedIndustry] = useState<IndustryEnum>();
	const [keywords, setKeywords] = useState<string>();
	const [industryPeers, setIndustryPeers] = useState<WaterfallPeer[]>();
	const [sectorPeers, setSectorPeers] = useState<WaterfallPeer[]>();
	const [isFiltering, setIsFiltering] = useState<boolean>(false);
	const [isByMultiple, setIsByMultiple] = useState<boolean>(false);
	const [volatilityData, setVolatilityData] = useState<VolatilityTableData>();
	const ref = useRef<HTMLDivElement>(null);

	useImperativeHandle(forwardedRef, () => ({
		onValidate: () => {
			return true;
		},
	}));

	useEffect(() => {
		(async () => {
			const [availablePeers, _, getAddedPeersRes] = await Promise.all([fetchAvailablePeers(), getPeers(), valuationStore.getAddedPeers()]);
			setIndustryPeers(availablePeers);
			setSectorPeers(availablePeers);
			setVolatilityData(getAddedPeersRes.data);
		})();

		return () => {
			setIndustryPeers(undefined);
			setSectorPeers(undefined);
			setIsFiltering(false);
		};
	}, []);

	const getPeers = async () => {
		const res = await valuationStore.getPeers();
		setVolatilityData(res.data);
	};

	const getSectorPeers = async () => {
		const peers = await fetchAvailablePeers({ sectorEnum: selectedSector, keywords });
		setSectorPeers(peers);
	};

	const getIndustryPeers = async () => {
		const peers = await fetchAvailablePeers({ industryEnum: selectedIndustry });
		setIndustryPeers(peers);
	};

	const fetchAvailablePeers = async (params?: PeerSearchParams) => {
		setIsFiltering(true);
		const res = await valuationStore.gerAvailablePeers(params);
		setIsFiltering(false);
		return res.data?.sort((a, b) => {
			return a.companyName > b.companyName ? 1 : -1;
		});
	};

	const onAddPeer = async (stockId: number) => {
		if (stockId === undefined || volatilityData === undefined) return;
		const identifier = sectorPeers?.find((p) => p.stockId === stockId)?.identifier;
		if (!identifier) return;

		const res = await calculatePeers({
			...volatilityData,
			peers: [...convertVolatilitiesToPeers(volatilityData.volatilities), identifier],
		});
		if (!res?.data) return;

		setVolatilityData((prev) =>
			prev
				? {
						...prev,
						volatilities: res.data.volatilities,
				  }
				: prev
		);
		ref.current?.scrollIntoView({ behavior: 'smooth' });
	};

	const calculatePeers = async (data: VolatilityTableData) => {
		if (volatilityData === undefined) return;
		const { volatilities, ...rst } = data;
		appState.isLoading = true;
		const res = await valuationStore.calculateAddedPeers(rst);
		appState.isLoading = false;
		return res;
	};

	const convertVolatilitiesToPeers = (data: VolatilityData[]) => {
		return data.reduce((acc, v) => {
			if (v.identitifier !== 'median' && v.identitifier !== 'average') {
				acc.push(v.identitifier);
			}
			return acc;
		}, [] as string[]);
	};

	return (
		<WaterfallStep small className={VolatilityStyle}>
			<WaterfallSubtitle>Volatility Measurement</WaterfallSubtitle>
			<div className={`${VolatilityStyle}__container`}>
				{SectionTitle("Define Evaluated Company's Sector & Industry")}

				<div className={`${VolatilityStyle}__filters`}>
					<div className="selectable">
						<Select
							qaid=""
							value={selectedSector}
							options={sectorOptions}
							onChange={(val) => {
								if (!isNumber(val)) return;
								setSelectedSector(val);
								getSectorPeers();
							}}
							label="Sector"
							required
							defaultValue="All sectors"
							isLoading={isFiltering}
						/>
						<Select
							qaid=""
							value={selectedIndustry}
							options={industryOptions}
							onChange={(val) => {
								if (!isNumber(val)) return;
								setSelectedIndustry(val);
								getIndustryPeers();
							}}
							label="Industry"
							isLoading={isFiltering}
						/>
					</div>
					<div className="keywords">
						<Label>Search by keywords (optional):</Label>
						<TextAreaInput
							style={{ resize: 'none', width: '100%' }}
							rows={2}
							maxLength={200}
							name="businessDescription"
							value={keywords}
							onChange={(e: any) => setKeywords(e.target.value)}
							data-qaid="Volatility.Input.Keywords"
							placeholder="Type peers' description with keywords. Separate terms by comma (,)"
							disabled={isFiltering}
						/>
						<Button qaid="Volatility.Button.Filter" label="Filter" position="end" onClick={getIndustryPeers} disabled={isFiltering || !keywords} />
					</div>
				</div>
			</div>
			<div className={`${VolatilityStyle}__container`}>
				{SectionTitle('Adding Peers')}

				<div className={`${VolatilityStyle}__peers`}>
					<div className="inputs">
						<AutoCompleteInput
							qaid=""
							options={sectorPeers
								?.filter((p) => !volatilityData?.volatilities.some((v) => v.identitifier === p.identifier))
								?.map((peer) => ({
									value: peer.stockId,
									label: (
										<div className="w-100 flex justify-between">
											<span>{peer.companyName}</span>
											<Tooltip title={getTooltip(peer)}>
												<span>Add</span>
											</Tooltip>
										</div>
									),
									description: peer.ticker,
								}))}
							onChange={(value) => isNumber(value) && onAddPeer(value)}
							value={undefined}
							placeholder="Search..."
							label="Type peer ticker or company name"
							endIcon={IC_LOOKUP}
							containerClassName="search-input"
						/>
						<div className="btn-show-list" data-qaid="Volatility.Button.ToggleList" onClick={() => setIsListOpen((prev) => !prev)}>
							<OverflowText>Show industry & keywords full list selection</OverflowText>
							<ExpandToggle isOpen={isListOpen} />
						</div>
						<div className="btn-ask-altshare" data-qaid="Volatility.Button.AskAltshare">
							Didn't find your peer company? Ask altshare
						</div>
					</div>
					<Collapse unmountOnExit in={isListOpen}>
						<div className="list-card" ref={ref}>
							<div className="list-title">Select peers (up to 12):</div>
							<div className="list-container">
								{industryPeers?.map((peer) => (
									<Tooltip title={getTooltip(peer)} key={peer.stockId}>
										<div>
											<CheckBox
												qaid=""
												label={peer.companyName}
												isChecked={volatilityData?.volatilities.some((v) => v.identitifier === peer.identifier)}
												type="secondary"
												onClick={async () => {
													if (!volatilityData) return;

													const isExists = volatilityData?.volatilities.some((v) => v.identitifier === peer.identifier);

													if (isExists) {
														const filteredVolatilities = volatilityData.volatilities.filter(
															(v) => v.identitifier !== peer.identifier
														);

														const res = await calculatePeers({
															...volatilityData,
															peers: convertVolatilitiesToPeers(filteredVolatilities),
														});
														if (res?.data?.volatilities) {
															setVolatilityData((prev) => (prev ? { ...prev, volatilities: res?.data?.volatilities } : prev));
														}
														return;
													}
													onAddPeer(peer.stockId);
												}}
											/>
										</div>
									</Tooltip>
								))}
							</div>
						</div>
					</Collapse>
				</div>
			</div>
			<div className={`${VolatilityStyle}__container`}>
				<CircularSwitchToggle
					value={isByMultiple}
					actions={[
						{ value: false, label: 'Peers volatility' },
						{ value: true, label: 'Peers multiples' },
					]}
					type="secondary"
					onChange={(val) => setIsByMultiple(val)}
					className="type-selection"
					size="sm"
				/>

				{isByMultiple ? (
					<MultiplesTable />
				) : (
					<VolatilityTable
						data={volatilityData}
						setData={async (newData) => {
							const res = await calculatePeers({ ...newData, peers: convertVolatilitiesToPeers(newData.volatilities) });
							if (res?.data?.volatilities) {
								setVolatilityData(newData);
							}
						}}
					/>
				)}
			</div>
		</WaterfallStep>
	);
});

const tempData = {
	volatilities: [
		{
			data: [
				{
					expectedTermsInYears: 1,
					volatility: 0.2523044469902399,
				},
				{
					expectedTermsInYears: 2,
					volatility: 0.23478272007575438,
				},
				{
					expectedTermsInYears: 3,
					volatility: 0.254665534717583,
				},
			],
			identitifier: '@AAPL',
			companyName: 'APPLE',
			ticker: 'AAPL',
		},
		{
			data: [
				{
					expectedTermsInYears: 1,
					volatility: 0.8859479857762798,
				},
				{
					expectedTermsInYears: 2,
					volatility: 1.00590435661106,
				},
				{
					expectedTermsInYears: 3,
					volatility: 1.0148896074712825,
				},
			],
			identitifier: 'US7672921050',
			companyName: 'RIOT PLATFORMS',
			ticker: 'RIOT',
		},
		{
			companyName: 'median',
			data: [
				{
					expectedTermsInYears: 1,
					volatility: 0.5691262163832598,
				},
				{
					expectedTermsInYears: 2,
					volatility: 0.6203435383434072,
				},
				{
					expectedTermsInYears: 3,
					volatility: 0.6347775710944328,
				},
			],
			identitifier: 'median',
			ticker: 'median',
		},
		{
			companyName: 'average',
			data: [
				{
					expectedTermsInYears: 1,
					volatility: 0.5691262163832598,
				},
				{
					expectedTermsInYears: 2,
					volatility: 0.6203435383434072,
				},
				{
					expectedTermsInYears: 3,
					volatility: 0.6347775710944328,
				},
			],
			identitifier: 'average',
			ticker: 'average',
		},
	],
};

const TooltipStyle = css({
	label: 'PeerTooltip',
	fontSize: '1.3rem',
	lineHeight: '1.8rem',
	letterSpacing: '2%',
	padding: '0.8rem',
	maxWidth: '40rem',
	fontFamily: appConfig.style.fonts.textPro,
	'.light': {
		fontWeight: 400,
	},
	'> div': {
		'> span:first-child': {
			marginRight: '0.4rem',
		},
	},
	'.comment': {
		color: '#AFAFAF',
	},
});

const getTooltip = (peer: WaterfallPeer) => {
	return (
		<div className={TooltipStyle}>
			<div>
				<span className="bold">Company name:</span>
				<span className="light">{peer.companyName}</span>
			</div>
			<div>
				<span className="bold">Ticker:</span>
				<span className="light">{peer.ticker}</span>
			</div>
			<div>
				<span className="bold">IPO Date:</span>
				<span className="light">{formatDate(peer.ipoDateTime)}</span>
			</div>
			<br />
			<div>
				<span className="bold">Market Cap:</span>
				<span className="light">{formatDecimal(peer.marketCap)}M</span>
			</div>
			<div>
				<span className="bold">Enterprise value:</span>
				<span className="light">{formatDecimal(peer.enterpriseValue)}M</span>
			</div>
			<div className="comment">
				<span>As of date:</span>
				<span className="light">{formatDate(peer.ipoDateTime)}</span>
			</div>
			<br />
			<div>
				<span className="bold">Description:</span>
				<span className="light">{peer.description}</span>
			</div>
		</div>
	);
};

const SectionTitle = (text: string) => {
	return (
		<>
			<InputLabel
				ap={{
					htmlFor: '',
					text,
				}}
			/>
			<Hr
				ap={{
					spacing: 'xs',
				}}
			/>
		</>
	);
};

export default Volatility;
