import { BenTable } from "../../Models/App/EquityPlans/Beneficiaries";
import { GrantTable } from "../../Models/App/EquityPlans/Grants";
import useModal from "../../Shared/Hooks/useModal";
import useRootStore from "../../Shared/Hooks/useRootStore";
import BeneficiaryCard from "./Sections/Beneficiaries/BeneficiaryCard/BeneficiaryCard";
import NewBeneficiaryDetails from "./Sections/Beneficiaries/NewBeneficiary/NewBeneficiaryDetails/NewBeneficiaryDetails";
import DataLog from "./Sections/DataLog/DataLog";
import GrantCard from "./Sections/Grants/GrantCard";
import NewGrantDetails from "./Sections/Grants/GrantDetails/NewGrant/NewGrantDetails";
import EditVestSched from "./Sections/Grants/VestsTable/EditVestSched/EditVestSched";
import ImportFromExcel from "./Sections/ImportFromExcel/Index";

import EditOrder from "./Sections/Orders/EditOrder/EditOrder";
import NewGrantCancelation from "./Sections/Cancelations/NewGrantCancelation/NewGrantCancelation";
import NewOrder from "./Sections/Orders/NewOrder/NewOrder";
import Registration from "./Sections/Registration";
import { OrderType } from "../../Models/App/EquityPlans/Order";
import Granticiary from "./Dashboard/GrantsTable/Granticiary/Granticiary";
import { ModalBodyProps } from "../../Shared/Components/Modal/types";
import { CompanyRegistrationEnum } from "./Sections/Registration/Registration";
import { EquityPlan } from "../../Models/App/EquityPlans/Company";
import { useTranslation } from "react-i18next";
import { HigherLevelResponse, NetworkStatusCode } from "../../Services/Axios";
import { clear } from "console";
import { JsxElement } from "typescript";
import ChangeDataSource from "./Sections/Settings/DataSource/ChangeDataSource";

const useEsopModals = () => {
    const { equityPlansStore, documentsStore } = useRootStore();
    const { showModal, clearModals, removeCurrentModal } = useModal();
    const { t, i18n } = useTranslation();

    // Beneficiary

    const newBeneficiaryModalHandler = (granticiary: boolean = false) => {
        showModal({
            body: <NewBeneficiaryDetails />,
            width: "90%",
            maxWidth: "140rem",
            isFrameless: true,
            isMust: true,
        });
    };

    const openBeneficiaryModal = async (beneficiaryId: number, isEdit = false) => {
        console.log("ESOP Modals - openBeneficiaryModal benId", beneficiaryId);
        // await Promise.allSettled([
        equityPlansStore.GetGrantsForBeneficiary(beneficiaryId);
        equityPlansStore.GetOrdersForBeneficiary(beneficiaryId);
        equityPlansStore.GetCancelationsForBeneficiary(beneficiaryId);
        equityPlansStore.GetBeneficiary(beneficiaryId);
        // ]);

        showModal({
            body: (
                <BeneficiaryCard
                    btnFunc={showCreateGrantModal}
                    openGrantModal={(grantId: number) => openGrntCard(grantId, beneficiaryId)}
                    openOrderModal={() => newOrderHandler(false, true)}
                    openCancelationModal={() => showCancelationModal(false, true)}
                    isEdit={isEdit}
                />
            ),
            width: "90%",
            height: "90%",
            maxWidth: "140rem",
            maxHeight: "95rem",
            isMust: true,
            isFrameless: true,
        });
    };

    const showDeleteBeneficiary = (beneficiaryId: number) => {
        showModal({
            type: "delete",

            body: (
                <>
                    <div>This action will delete this beneficiary and all of his</div>
                    <div>Grants, orders and cancelations</div>
                    <div>Do you wish to proceed?</div>
                </>
            ),
            onConfirm: async () => {
                const res = await equityPlansStore.DeleteBeneficiary_Qa(beneficiaryId);
                if (res.data) {
                    clearModals();
                    showSuccessModal("The beneficiary was deleted successfully");

                    equityPlansStore.GetBeneficiariesDashboardData();
                }
                // props.removeModal?.();
            },
        });
    };

    const showGrantModal = (grantId?: number) => {
        if (grantId) {
            // equityPlansStore.
        } else if (equityPlansStore.beneficiaryGrantData?.grantId) {
            // equityPlansStore.GetModifiedGrantEvents(equityPlansStore.beneficiaryGrantData.grantId);
            equityPlansStore.GetOriginalGrantEvents(equityPlansStore.beneficiaryGrantData.grantId);
        }
        showModal({
            // title: `Grant #: ${equityPlansStore.grant?.grantNumber} `,
            body: <GrantCard />,
            height: "100%",
            width: "70%",
            isFrameless: true,
        });
    };

    // const showOrderModal = () => {
    // 	showModal({
    // 		title: `New Grant Order For: ${equityPlansStore.beneficiaryData?.firstName ?? ""} ${
    // 			equityPlansStore.beneficiaryData?.lastName ?? ""
    // 		} `,
    // 		body: <NewOrder />,
    // 		height: "80%",
    // 		width: "60%",
    // 		// isLast: true
    // 	});
    // };

    const showCancelationModal = (showBen?: boolean, showGrant?: boolean, grantId?: number, beneficiaryId?: number) => {
        showModal({
            // title: `New Grant Cancelation For: ${equityPlansStore.beneficiaryData?.firstName ?? ""} ${equityPlansStore.beneficiaryData?.lastName ?? ""} `,
            body: <NewGrantCancelation showBen={showBen} showGrant={showGrant} grantId={grantId} beneficiaryId={beneficiaryId} />,
            width: "90%",
            maxWidth: "100rem",
            allowScrolling: false,
            isFrameless: true,
            isMust: true,
        });
    };

    const showGrantCancelationQA = () => {
        showModal({
            // title: `New Grant Cancelation For: ${equityPlansStore.beneficiaryData?.firstName ?? ""} ${equityPlansStore.beneficiaryData?.lastName ?? ""} `,
            body: (
                <NewGrantCancelation
                // showBen={showBen}
                // showGrant={showGrant}
                // grantId={grantId}
                // beneficiaryId={beneficiaryId}
                // onSave={async () => {
                // 	console.log("canceling!!!!");
                // 	const res = await equityPlansStore.CancelGrantQA();
                // 	console.log("CACNCELLING RESSS", res);
                // 	if (res?.data[0].companyId) {
                // 		clearModals();
                // 		showModal({
                // 			type: "success",
                // 			body: <></>,
                // 			timeout: 1500,
                // 		});
                // 	} else {
                // 		showErrorModal();
                // 	}
                // }}
                />
            ),

            isFrameless: true,
            // height: "100%",
            // width: "70%",
        });
    };

    const showTerminationModal = (beneficiaryId: number) => {
        showModal({
            body: <NewGrantCancelation beneficiaryId={beneficiaryId} qaTermination />,
            // height: "100%",
            // width: "70%",
        });
    };

    // const showTerminationModal = (beneficiaryId: number) => {
    // 	showModal({
    // 		// title: `New Grant Cancelation For: ${equityPlansStore.beneficiaryData?.firstName ?? ""} ${equityPlansStore.beneficiaryData?.lastName ?? ""} `,
    // 		body: <NewGrantCancelation
    // 			// showBen={showBen}
    // 			// showGrant={showGrant}
    // 			// grantId={grantId}
    // 			beneficiaryId={beneficiaryId}
    // 			onSave={async () => {
    // 				console.log('canceling!!!!')
    // 				const res = await equityPlansStore.TerminateEmployeeQa(beneficiaryId)
    // 				console.log('CACNCELLING RESSS', res)
    // 				if (res[0].companyId) {
    // 					clearModals()
    // 					showModal({
    // 						type: 'success',
    // 						body: <></>,
    // 						timeout: 1500
    // 					})
    // 				} else {
    // 					showErrorModal()
    // 				}

    // 			}}
    // 		/>,
    // 	})
    // }

    const showCreateGrantModal = (selectBen?: boolean) => {
        showModal({
            body: (
                <NewGrantDetails
                    onSave={async () => {
                        // console.log('adding grant to beneficiary onsave')
                        const res = await equityPlansStore.AddGrantToBeneficiary();
                        // console.log("first RES", res);
                        if (res.data?.grantId) {
                            removeCurrentModal();
                            showSuccessModal("The grant was added successfully");
                        } else {
                            showErrorModal(res);
                        }
                    }}
                    selectBen={selectBen}
                />
            ),
            width: "90%",
            maxWidth: "140rem",
            isFrameless: true,
            isMust: true,
        });
    };

    const openGrntCard = (grantId: number, beneficiaryId: number, isEdit = false) => {
        equityPlansStore.GetSingleGrant(grantId);
        equityPlansStore.GetOrdersForSingleGrant(beneficiaryId, grantId);
        equityPlansStore.GetCancelationOrdersForSingleGrant(beneficiaryId, grantId);
        equityPlansStore.GetOriginalGrantEvents(grantId);
        // equityPlansStore.GetModifiedGrantEvents(grantId);
        showModal({
            // title: `Grant # ${grantNumber}`,
            body: <GrantCard isEdit={isEdit} />,
            width: "90%",
            height: "90%",
            maxWidth: "140rem",
            maxHeight: "95rem",
            isMust: true,
            isFrameless: true,
        });
    };

    const showAddNewGrantWithBeneficiary = () => {
        showModal({
            body: ({ removeModal }) => (
                <Granticiary
                    onSave={async (grantLetterTemplateId, isIso) => {
                        const res = await equityPlansStore.CreateGrant();
                        if(res.statusCode === NetworkStatusCode.PAYMENT_REQUIRED) return;

                        if (!res.data?.[0]?.grantId) {
                            showErrorModal(res);
                            return
                        }

                        if (grantLetterTemplateId) {
                            await equityPlansStore.createGrantLetter(res.data[0].grantId, grantLetterTemplateId, documentsStore.templates?.find((t) => t.templateId === grantLetterTemplateId)?.variablesCollection)
                        }
                        equityPlansStore.GetGrantsForCompany();
                        equityPlansStore.ResetGrant();
                        removeModal?.();
                        showSuccessModal(isIso ? 'Split is successfully completed into ISO and NSO' : '');
                        // openGrntCard(res.data.grantId, res.data.beneficiaryId, res.data.grantNumber);
                    }}
                />
            ),
            width: "90%",
            maxWidth: "125rem",
            isFrameless: true,
            isMust: true,
        });
    };

    const showIsoModal = async (confirm: any) => {
        showModal({
            type: 'warning',
            title: 'ISO/NSO Split',
            body: ({ }) => (
                <>
                    <span>Please note that the beneficiary has exceeded the $100,000 limit & therefore</span>
                    <span>the grant should be split into 2 different tax tracks of ISO & NSO</span>
                </>
            ),
            width: "50%",
            isMust: true,
            onConfirm: () => confirm(true),
            confirmButton: {
                label: 'Confirm'
            },
        });
    }

    // Remove
    // const addGrantHandler = (selectBen?: boolean) => {
    // 	showModal({
    // 		body: (
    // 			<NewGrantDetails
    // 				onSave={async (keepOpen: boolean) => {
    // 					const res = await equityPlansStore.CreateGrant();
    // 					if (keepOpen) return;
    // 					clearModals();
    // 					openGrntCard(res.data.grantId, res.data.beneficiaryId, res.data.grantNumber);
    // 				}}
    // 				selectBen={selectBen}
    // 			/>
    // 		),
    // 		maxWidth: "106.3rem",
    // 		width: "90%",
    // 		height: "90%",
    // 		isFrameless: true,
    // 		isMust: true,
    // 	});
    // };

    const showDeleteGrantModal = (grantId: number) => {
        showModal({
            type: "delete",
            title: "Delete grant",
            body: "Are you sure you want to permanently delete this grant and all of its relevant transactions?",
            onConfirm: async () => {
                const res = await equityPlansStore.DeleteGrant(grantId);
                console.log("DeleteGrant RESPONSE", res);
                if (res.data) {
                    showSuccessModal("The grant was deleted successfully");
                } else {
                    showErrorModal();
                }
                // props.removeModal?.();
            },
        });
    };

    const editVestSchedHandler = () => {
        showModal({
            title: `Edit Vesting Schedul For Grant ${equityPlansStore.beneficiaryGrantData.grantNumber}`,
            body: <EditVestSched />,
            width: "60%",
        });
    };

    // Orders

    const onEditOrderHandler = (number: number | string) => {
        showModal({
            title: `Order: ${number}`,
            body: <EditOrder />,
            width: "60%",
        });
    };

    const newOrderHandler = (selectBen?: boolean, selectGrant?: boolean) => {
        showModal({
            // title: `New Grant Order For: ${equityPlansStore.beneficiaryData?.firstName ?? ""} ${equityPlansStore.beneficiaryData?.lastName ?? ""} `,
            body: <NewOrder selectBen={selectBen} selectGrant={selectGrant} />,
            width: "90%",
            maxWidth: "100rem",
            isMust: true,
            allowScrolling: false,
            isFrameless: true,
        });
    };

    const qA_newOrderHandler = (orderType: OrderType, grantId: number, beneficiaryId: number) => {
        showModal({
            // title: `New Grant Order For: ${equityPlansStore.beneficiaryData?.firstName ?? ""} ${equityPlansStore.beneficiaryData?.lastName ?? ""} `,
            body: <NewOrder grantId={grantId} beneficiaryId={beneficiaryId} orderType={orderType} />,
            isFrameless: true,

            // width: "60%",
        });
    };

    // General

    const onImportDataHandler = () => {
        showModal({
            body: <ImportFromExcel />,
            title: "Import Data From Excel",
            fullscreen: true,
            isMust: true,
        });
    };

    const onLogHandler = () => {
        showModal({
            title: "Data Log",
            body: <DataLog />,
            width: "70%",
        });
    };

    const onRegistrationHandler = (step: CompanyRegistrationEnum = CompanyRegistrationEnum.intro, onModalClose?: () => void) => {
        showModal({
            body: <Registration startingStep={step} />,
            isFrameless: true,
            width: "100rem",
            isMust: true,
            onModalClose,
        });
    };

    // const deleteGrant = (grantId: number, beneficiaryId: number) => {
    // 	showModal({
    // 		body: <>Delete This Fucker!!!</>,
    // 		isFrameless: true,
    // 		width: "60%",
    // 		// isMust: true,
    // 	});
    // };

    // const deleteBeneficiary = (beneficiaryId: number) => {
    // 	showModal({
    // 		body: <>Delete This Fucker!!!</>,
    // 		isFrameless: true,
    // 		width: "60%",
    // 		height: "60%",
    // 		// isMust: true,
    // 	});
    // };

    const inviteToEmployeeManagment = async (beneficiaryId: number, email: string, benName: { firstName: string; lastName: string }) => {
        if (!email) {
            showModal({
                body: <>{`${benName.firstName}, ${benName.lastName} does not wave a valid email address`}</>,
                type: "error",
            });
        } else {
            const res = await equityPlansStore.InviteToEmployeePortal(beneficiaryId);
            console.log("Invitation Modal RES", res);
            if (res?.data) {
                showSuccessModal(`The invitation was sent successfully to ${email}`);
            } else {
                showErrorModal();
            }
        }
    };

    const showDeletePlanModal = (planId: number) => {
        showModal({
            body: <>Are you sure you want to delete this plan?</>,
            type: "delete",
            onConfirm: async () => {
                console.log("Deleting Plan ", planId);
                const res = await equityPlansStore.DeleteEquityPlan(planId);
                console.log("Delete RES: ", res);
                if (res.data) {
                    showSuccessModal("The plan was deleted successfully");
                } else {
                    showErrorModal(res);
                }
            },
        });
    };

    const showErrorModal = (errorRes?: HigherLevelResponse<any> | any, body = "Something went wrong, Please Try Again", title: string = "Error") => {

        if (errorRes?.error) {
            if (Array.isArray(errorRes.error?.data)) {
                const errors = errorRes.error.data
                console.log('Array');
                showModal({
                    body: () => (
                        <ul>
                            {
                                errors.map((err: HigherLevelResponse<any>) => (
                                    <li key={err.errorCode}>
                                        * {i18n.exists(`errors.equityPlans.${err.errorCode}`) ? t(`errors.equityPlans.${err.errorCode}`) : ''}
                                    </li>
                                ))
                            }
                        </ul>
                    ),
                    title,
                    type: "error",
                });
            } else {
                console.log('no Array');
                showModal({
                    body: i18n.exists(`errors.equityPlans.${errorRes.errorCode}`) ? t(`errors.equityPlans.${errorRes.errorCode}`) : body,
                    title,
                    type: "error",
                });
            }
        } else {
            console.log('no Text');
            showModal({
                body: body,
                title,
                type: "error",
            });
        }
        console.log('nothing');
    };

    const showSuccessModal = (body?: JsxElement | string) => {
        showModal({
            body: <>{body}</>,
            type: "success",
            timeout: 1400,
            width: "44.5rem",
            showProgressBar: false,
        });
    };

    const showChangeDataSource = (fromPivotal: boolean, initialRegistrartion?: boolean) => {
        showModal({
            body: <ChangeDataSource fromPivotal={fromPivotal} initialRegistrartion />,
            width: '44.5rem'
        })
    }

    return {
        openBeneficiaryModal,
        newBeneficiaryModalHandler,
        showCreateGrantModal,
        openGrntCard,
        clearModals,
        // addGrantHandler,
        editVestSchedHandler,
        onImportDataHandler,
        onLogHandler,
        onEditOrderHandler,
        newOrderHandler,
        onRegistrationHandler,
        showCancelationModal,
        inviteToEmployeeManagment,
        qA_newOrderHandler,
        showDeleteGrantModal,
        showTerminationModal,
        showDeleteBeneficiary,
        showAddNewGrantWithBeneficiary,
        showDeletePlanModal,
        showErrorModal,
        showGrantCancelationQA,
        showSuccessModal,
        // deleteGrant,
        // deleteBeneficiary,
        showChangeDataSource,
        showIsoModal
    };
};

export default useEsopModals;
