import { css } from '@emotion/css';
import DatePickerInput from '../../../../../Shared/Components/Input/DatePickerInput';
import { TextAreaInput } from '../../../../../Shared/Components/Input/Input.Style';
import RadioButton from '../../../../../Shared/Components/RadioButton/RadioButton';
import Tooltip from '../../../../../Shared/Components/Tooltip';
import appConfig from '../../../../../config/config';
import { DataCollectionComment } from '../../../../../Models/API/DataCollection/comment-response';
import Button from '../../../../../Shared/Components/Button/Button';
import { IC_PLUS_PURPLE } from '../../../../../Assets';
import { Collapse } from '@mui/material';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { ForwardedRefType } from '../../../../../Shared/Hooks/useMultiStepForm';

type Props = {
	title: string;
	info?: string;
	comments?: DataCollectionComment[];
	renderActions: JSX.Element;
};

const Style = css({
	label: 'DataCollectionQuestion',
	display: 'flex',
	flexDirection: 'column',
	padding: '2rem 0',
	'&:not(:last-child)': {
		borderBottom: `1px solid ${appConfig.style.colors.table}`,
	},
	'&__question-lbl': {
		display: 'flex',
		alignItems: 'center',
		gap: '1.2rem',
		marginBottom: '2rem',
	},
	'.actions': {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
	},
	'.collapsed-input': {
		marginTop: '2rem',
	},
});

const Question = forwardRef<ForwardedRefType<string | undefined>, Props>(({ title, info, comments, renderActions }, forwardedRef) => {
	const [isCommentOpen, setIsCommentOpen] = useState<boolean>(false);
	const [comment, setComment] = useState<string>();

	const onToggleShow = () => {
		setIsCommentOpen((prev) => !prev);
		// setComment(undefined);
	};

	useImperativeHandle(forwardedRef, () => ({
		onValidate: () => true,
		getData: () => comment,
	}));

	return (
		<div className={Style}>
			<div className={`${Style}__question-lbl`}>
				{title}

				<Tooltip title={info} />
			</div>

			<div className="actions">
				{renderActions}
				{comments !== undefined &&
					(comments.length ? <div>{comments.length} Comments</div> : <Button qaid="" label="Add comment" inverse cancel onClick={onToggleShow} />)}
			</div>
			<Collapse in={isCommentOpen} unmountOnExit className="collapsed-input">
				<TextAreaInput
					data-qaid=""
					value={comment}
					onChange={(e) => setComment(e.target.value)}
					style={{ resize: 'none', height: '100%', width: '100%' }}
					rows={4}
					maxLength={500}
					placeholder="Type text here..."
					name=""
				/>
			</Collapse>
		</div>
	);
});

export default Question;
