import { useHistory } from 'react-router-dom';
import Button from '../../Shared/Components/Button/Button';
import { Routes } from '../../Routes';
import useRootStore from '../../Shared/Hooks/useRootStore';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

const DataCollection = () => {
	const history = useHistory();
	const { dataCollectionStore } = useRootStore();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const onOrderHandler = async () => {
		setIsLoading(true);
		const res = await dataCollectionStore.createProject();
		if (!res.data?.data.valuationProjectId) return;

		const dcRes = await dataCollectionStore.createDataCollection({
			valuationProjectId: res.data.data.valuationProjectId,
		});
		setIsLoading(false);
		if (!dcRes.data?.data.dataCollectionId) return;

		dataCollectionStore.currentProjectId = res.data.data.valuationProjectId;
		history.push(`${Routes.valuation.dataCollection}/${dcRes.data.data.dataCollectionId}/0`);
	};

	return (
		<div>
			<div>Data Collection</div>
			<Button qaid="DataCollection.Button.Order409A" label="Order new 409A" onClick={onOrderHandler} isLoading={isLoading} />
		</div>
	);
};

export default observer(DataCollection);
