import { createRef, SyntheticEvent, useEffect, useState } from 'react';
import appConfig from '../../../config/config';
import Button from '../../../Shared/Components/Button/Button';
import ProgressBar from '../../../Shared/Components/ProgressBar';
import useMultiStepForm, { ForwardedRef, MultiStepFormItem } from '../../../Shared/Hooks/useMultiStepForm';
import { WaterfallFooter } from '../../Waterfall/Components/AddEditWaterfall/index.style';
import { ActionsMenuStyle } from '../../Waterfall/Components/WaterfallHeader/WaterfallHeader.style';
import AddDocuments from './Steps/AddDocuments';
import GeneralInfo from './Steps/GeneralInfo';
import ImportCapTable from './Steps/ImportCapTable';
import useRootStore from '../../../Shared/Hooks/useRootStore';
import { useHistory, useParams } from 'react-router-dom';
import { Routes } from '../../../Routes';

const AddEditDataCollection = () => {
	const { dataCollectionStore } = useRootStore();
	const params = useParams<{ step: string }>();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const ref = createRef<ForwardedRef>();
	const { step, onNextStep, onPrevStep, goTo, currentStepIdx, steps, isLastStep, isFirstStep } = useMultiStepForm([
		{
			element: <GeneralInfo ref={ref} />,
			caption: 'General information',
		},
		{
			element: <ImportCapTable />,
			caption: 'Import cap table',
		},
		{
			element: <AddDocuments />,
			caption: 'Add documents',
		},
	]);

	useEffect(() => {
		const step = +params.step;

		if (!isNaN(step)) goTo(step);

		return () => {
			// history.push(Routes.waterfall.index);
			dataCollectionStore.resetValuationProject();
		};
	}, []);

	useEffect(() => {
		console.log(currentStepIdx);
		if (!dataCollectionStore.currentProjectId) return;
		history.replace(`${Routes.valuation.dataCollection}/${dataCollectionStore.currentProjectId}/${currentStepIdx}`);
	}, [currentStepIdx, dataCollectionStore.currentProjectId]);

	const onStepClickHandler = (idx: number) => {
		goTo(idx);
	};

	const onSubmit = async (e: SyntheticEvent) => {
		e?.preventDefault();
		if (isLoading) return;

		setIsLoading(true);
		await ref.current?.onValidate();
		setIsLoading(false);

		if (!isLastStep) {
			return onNextStep(); // If not last step, continue to next step
		}

		// Otherwise submit
		// alert("Saved!");
	};

	return (
		<>
			<ProgressBar
				pageCaptions={steps.map((step: MultiStepFormItem) => step.caption || '')}
				onStepClickHandler={onStepClickHandler}
				currentStepIdx={currentStepIdx}
				title={
					<>
						<span style={{ color: appConfig.style.colors.text2 }}>Analysis name:</span> Valuation name: N/A
					</>
				}
				lastCompletedIdx={-1}
				actions={
					<div className={ActionsMenuStyle}>
						{/* <Button qaid="ProgressBar.Button.Save" onClick={onSaveHandler} inverse label="Save as" className="button--waterfall" /> */}
						<Button
							qaid="ProgressBar.Button.Quit"
							onClick={() => console.log('onSaveHandler')}
							label="Save & Quit"
							cancel
							className="button--waterfall"
						/>
					</div>
				}
				isLastStepHighlighed
			/>
			{step}
			<WaterfallFooter>
				{!isFirstStep && (
					<Button qaid="AddEditWaterfall.Button.Back" type="button" disabled={isLoading} inverse onClick={onPrevStep} label="general.back" />
				)}

				<Button
					style={{ marginLeft: 'auto' }}
					qaid="AddEditWaterfall.Button.Next"
					isLoading={isLoading}
					onClick={onSubmit}
					label={isLastStep ? 'general.finish' : 'general.next'}
				/>
			</WaterfallFooter>
		</>
	);
};

export default AddEditDataCollection;
