import 'animate.css';
import { configure, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { hot } from 'react-hot-loader/root';
import { hotjar } from 'react-hotjar';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Router from './Router';
import { Routes } from './Routes';
import MobileRegCompleteMsg from './Screens/Account/Components/AccountForms/MobileRegCompleteMsg';
import { Footer, Loader } from './Shared/Components';
import Header from './Shared/Components/Header/Header';
import Spinner from './Shared/Components/Spinner/Spinner';
import useGeneralModal from './Shared/Hooks/useGeneralModal';
import useModal from './Shared/Hooks/useModal';
import useRootStore from './Shared/Hooks/useRootStore';
import useScreenSize from './Shared/Hooks/useScreenSize';
import './Shared/Middleware/i18n';
import { getCalculatedFontSize, getLocalStorage, isNullOrUndefined, print, setLocalStorage } from './Shared/Utilities';
import './stylesheets/main.scss';
import usePermissions from './Shared/Hooks/usePermissions';
import { UserStatus } from './Models/API/enums';
configure({
	enforceActions: 'never',
});

const App = () => {
	const { auth, appState, companyStore, documentsStore, paymentStore } = useRootStore();
	const rootRef = useRef<HTMLDivElement>(null);
	const { clearModals, showModal } = useModal();
	const location = useLocation();
	const { clearListener, isMobile } = useScreenSize();
	const { t } = useTranslation();
	const { onReachedLimitation, onShowGeneralInfo } = useGeneralModal();
	const { permissions } = usePermissions(auth.permissions, paymentStore.currentPlan);

	useEffect(() => {
		const fontSize = getCalculatedFontSize();
		if (isNullOrUndefined(fontSize)) return; // If no condition is matched, do nothing and use the default CSS

		appState.setFontSize(fontSize); // Daniel: DO NOT REMOVE, there are a few components that calculate properties based on the app's font size
		document.documentElement.style.setProperty('font-size', `${fontSize}px`);
		print(`App font size: ${fontSize}`);
	}, [window.screen.width]);

	useEffect(() => {
		auth.setRecaptchaKey(process.env.REACT_APP_RECAPTCHA_KEY);

		const isSilentCached = getLocalStorage('silent');
		const isSilent = location.hash?.includes('#silent') || isSilentCached;

		if (isSilent) {
			if (!isSilentCached) {
				// Happens once only if doesn't exist
				setLocalStorage('silent', isSilent);
				print('Marking current browser to ignore analytics');
			}
			print('Current browser is not being tracked!');
			return clearListener;
		}

		if (process.env.REACT_APP_MEASUREMENT_ID) {
			print(`Initializing GA: ${process.env.REACT_APP_MEASUREMENT_ID}`, 'success');
			ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID);
		}
		if (process.env.REACT_APP_GTM_ID) {
			print(`Initializing GTM: ${process.env.REACT_APP_GTM_ID}`, 'success');
			TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
		}
		if (process.env.REACT_APP_HOTJAR_ID && document.location.protocol === 'https:') {
			print(`Initializing Hotjar: ${process.env.REACT_APP_HOTJAR_ID}`, 'success');
			hotjar.initialize(parseInt(process.env.REACT_APP_HOTJAR_ID), 6);
		}

		return clearListener;
	}, []);

	useEffect(() => {
		if (appState.isProd) return;

		const handleKeyDown = (e: any) => {
			if (e.key === 'F10') {
				onShowGeneralInfo();
			}
		};

		document.addEventListener('keydown', handleKeyDown, false);

		return () => {
			document.removeEventListener('keydown', handleKeyDown, false);
		};
	}, []);

	useEffect(() => {
		// Daniel: scroll to top on every page change
		clearModals();
		if (!rootRef.current) return;
		// rootRef.current.scrollTop = 0;
	}, [location]);

	useEffect(() => {
		if (!companyStore.planLimitation.show) return;

		onReachedLimitation();
	}, [companyStore.planLimitation]);

	useEffect(() => {
		if (isNullOrUndefined(appState.errorCode) || !JSON.parse(process.env.REACT_APP_SHOW_ERROR_MODAL ?? 'false')) return;
		showModal({
			type: 'error',
			title: 'Error',
			body: `Error code ${t(appState.errorCode.toString())}`,
		});
	}, [appState.errorCode]);

	useEffect(() => {
		if (!auth.isLogged || appState.isDev) return;

		const tokenInterval = setInterval(() => {
			if (!appState.isTokenExpired) return;
			console.log(`%cLogging out due to inactivity`, 'background: red; padding: 4px; color: white; font-weight: bold; font-size: 20px');

			clearInterval(tokenInterval);
			auth.logOut();
		}, 30000);
	}, [auth.authToken]);

	if (auth.isLoading) {
		return (
			<div className="loader-container">
				<Loader />
			</div>
		);
	}

	if (!auth.isHydrated || isNullOrUndefined(companyStore.companyId)) return <Spinner />;

	const currentUrl = window.location.href;

	const isExpensingWithoutPlan = currentUrl.includes(Routes.expensing.index) && !permissions.expensing.included;
	const isWaterfallWithoutPlan = currentUrl.includes(Routes.waterfall.index) && !permissions.waterfall.included;

	const isOnPaymentPage = currentUrl.includes(Routes.payment.pricing) || currentUrl.includes(Routes.payment.paymentPay);
	const showFooter =
		auth.isUserActive &&
		location.pathname !== '/' &&
		!isOnPaymentPage &&
		!currentUrl.includes(Routes.account.completeRegisration) &&
		!currentUrl.includes('waterfall') &&
		!currentUrl.includes('409') &&
		!currentUrl.includes(Routes.capTable.import) &&
		!isExpensingWithoutPlan &&
		!isWaterfallWithoutPlan;
	const showMobRegMsg = isMobile && auth.isUserActive && !isOnPaymentPage && !currentUrl.includes(Routes.account.completeRegisration);
	const showHeader = ((auth.isUserActive && !isMobile) || (isOnPaymentPage && auth.isUserActive)) && !currentUrl.includes(Routes.account.completeRegisration);

	return (
		<div className="App" ref={rootRef}>
			{appState.isLoading && <Spinner />}
			{showHeader && <Header />}
			{showMobRegMsg && <MobileRegCompleteMsg />}
			{auth.isLogged && !paymentStore.currentPlan.planId && auth.userInfo.status === UserStatus.Active ? (
				<Spinner />
			) : (
				<main className="main">
					<Router />
				</main>
			)}
			{showFooter && <Footer />}
		</div>
	);
};

export default hot(observer(App));
