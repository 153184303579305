import NO_ACCESS from './images/icons/permissions_no_access.svg';
import VIEW from './images/icons/permissions_view.svg';
import EDITOR from './images/icons/permissions_editor.svg';

import captableTitle from './images/icons/permissions_captable.svg';
import captableNoAccess from './images/icons/permissions_captable_no_access.svg';
import captableViewer from './images/icons/permissions_captable_viewer.svg';
import captableEditor from './images/icons/permissions_captable_editor.svg';

import fundingTitle from './images/icons/permissions_funding.svg';
import fundingNoAccess from './images/icons/permissions_funding_no_access.svg';
import fundingViewer from './images/icons/permissions_funding_viewer.svg';
import fundingEditor from './images/icons/permissions_funding_editor.svg';

import waterfallTitle from './images/icons/permissions_waterfall.svg';
import waterfallNoAccess from './images/icons/permissions_waterfall_no_access.svg';
import waterfallViewer from './images/icons/permissions_waterfall_viewer.svg';
import waterfallEditor from './images/icons/permissions_waterfall_editor.svg';

import optionsTitle from './images/icons/permissions_options.svg';
import optionsNoAccess from './images/icons/permissions_options_no_access.svg';
import optionsViewer from './images/icons/permissions_options_viewer.svg';
import optionsEditor from './images/icons/permissions_options_editor.svg';

import expensingTitle from './images/icons/permissions_expensing.svg';
import expensingNoAccess from './images/icons/permissions_expensing_no_access.svg';
import expensingViewer from './images/icons/permissions_expensing_viewer.svg';
import expensingEditor from './images/icons/permissions_expensing_editor.svg';

import customPlan from './images/icons/plans_custom.svg';
import growthPlan from './images/icons/plans_growth.svg';
import scalePlan from './images/icons/plans_scale.svg';
import starterPlan from './images/icons/plans_starter.svg';

// tabs
import tabDashboard from './images/icons/tab_dashboard.svg';
import tabDashboardHover from './images/icons/tab_dashboard_hover.svg';
import tabCaptable from './images/icons/tab_captable.svg';
import tabCaptableHover from './images/icons/tab_captable_hover.svg';
import tabFunding from './images/icons/tab_funding.svg';
import tabFundingHover from './images/icons/tab_funding_hover.svg';
import tabWaterfall from './images/icons/tab_waterfall.svg';
import tabWaterfallHover from './images/icons/tab_waterfall_hover.svg';
import tabOptions from './images/icons/tab_options.svg';
import tabOptionsHover from './images/icons/tab_options_hover.svg';
import tabExpensing from './images/icons/tab_expensing.svg';
import tabExpensingHover from './images/icons/tab_expensing_hover.svg';
import tabDocs from './images/icons/tab_docs.svg';
import tabDocsHover from './images/icons/tab_docs_hover.svg';
import tab409A from './images/icons/tab_409A.svg';
import tab409AHover from './images/icons/tab_409A_hover.svg';
import tabEmployee from './images/icons/tab_employee.svg';
import tabEmployeeHover from './images/icons/tab_employee_hover.svg';
import tabUsersAndPermissions from './images/icons/tab_users_permissions.svg';
import tabCompanyProfile from './images/icons/tab_company_profile.svg';
import tabAddCompany from './images/icons/tab_add_company.svg';

export const IC_TABS = {
	DASHBOARD: {
		DEFAULT: tabDashboard,
		HOVER: tabDashboardHover,
	},
	CAPTABLE: {
		DEFAULT: tabCaptable,
		HOVER: tabCaptableHover,
	},
	FUNDING: {
		DEFAULT: tabFunding,
		HOVER: tabFundingHover,
	},
	WATERFALL: {
		DEFAULT: tabWaterfall,
		HOVER: tabWaterfallHover,
	},
	OPTIONS: {
		DEFAULT: tabOptions,
		HOVER: tabOptionsHover,
	},
	EMPLOYEE: {
		DEFAULT: tabEmployee,
		HOVER: tabEmployeeHover,
	},
	ACTIONS: {
		USERS_AND_PERMISSIONS: tabUsersAndPermissions,
		COMPANY_PROFILE: tabCompanyProfile,
		ADD_COMPANY: tabAddCompany,
	},
	EXPENSING: {
		DEFAULT: tabExpensing,
		HOVER: tabExpensingHover,
	},
	DOCS: {
		DEFAULT: tabDocs,
		HOVER: tabDocsHover,
	},
	Valuation409A: {
		DEFAULT: tab409A,
		HOVER: tab409AHover,
	},
};

export const IC_PERMISSIONS = {
	NO_ACCESS,
	VIEW,
	EDITOR,
};

export const IC_USER_PERMISSIONS = {
	CAPTABLE: {
		NO_ACCESS: captableNoAccess,
		VIEWER: captableViewer,
		EDITOR: captableEditor,
		TITLE: captableTitle,
	},
	FUNDING: {
		NO_ACCESS: fundingNoAccess,
		VIEWER: fundingViewer,
		EDITOR: fundingEditor,
		TITLE: fundingTitle,
	},
	WATERFALL: {
		NO_ACCESS: waterfallNoAccess,
		VIEWER: waterfallViewer,
		EDITOR: waterfallEditor,
		TITLE: waterfallTitle,
	},
	OPTIONS: {
		NO_ACCESS: optionsNoAccess,
		VIEWER: optionsViewer,
		EDITOR: optionsEditor,
		TITLE: optionsTitle,
	},

	Expensing: {
		NO_ACCESS: expensingNoAccess,
		VIEWER: expensingViewer,
		EDITOR: expensingEditor,
		TITLE: expensingTitle,
	},
};

export const IC_PLANS = {
	CUSTOM: customPlan,
	GROWTH: growthPlan,
	SCALE: scalePlan,
	STARTER: starterPlan,
};

export { default as IC_PAYMENT_BILLING } from './images/icons/payment_billing.svg';
export { default as IC_PAYMENT_CARD } from './images/icons/payment_card.svg';
export { default as IC_PAYMENT_STAKEHOLDERS } from './images/icons/payment_stakeholders.svg';
export { default as IC_PAYMENT_DEACTIVATE } from './images/icons/payment_deactivate.svg';
export { default as IC_CONTACT_US } from './images/icons/contact_us.svg';
export { default as IC_ARROW_DOWN_CIRCLE } from './images/icons/arrow_down_circle.svg';
export { default as IC_ARROW_UP_CIRCLE } from './images/icons/arrow_up_circle.svg';
export { default as IC_ARROW_RIGHT } from './images/icons/arrow_right.svg';
export { default as IC_ARROW_RIGHT_WHITE } from './images/icons/arrow_right_white.svg';

export { default as IC_HEADER_MENU } from './images/icons/header_menu.svg';

export { default as IC_CHECK_PURPLE } from './images/icons/checkmark_purple.svg';
export { default as IC_CHECK_WHITE2 } from './images/icons/checkmark_white.svg';
export { default as IC_CHECK_SECONDARY } from './images/icons/checkmark_secondary.svg';
export { default as IC_CHECK_GRAY } from './images/icons/checkmark_gray.svg';
export { default as IC_COMPANY_MENU } from './images/icons/add_company.svg';
export { default as IC_DUPLICATE2 } from './images/icons/duplicate.svg';
export { default as IC_NEW_DRAFT } from './images/icons/create_new_draft.svg';
export { default as IC_EXCEL_EXPORT } from './images/icons/export_to_excel.svg';
export { default as IC_UPLOAD_FILE } from './images/icons/upload_file.svg';
export { default as IC_FULLSCREEN_EXPAND } from './images/icons/fullscreen_expand.svg';
export { default as IC_FULLSCREEN_SHRINK } from './images/icons/fullscreen_shrink.svg';
export { default as IC_HINT } from './images/icons/hint_lamp.svg';
export { default as IC_EDIT2 } from './images/icons/edit.svg';
export { default as IC_SUCCESS_MODAL } from './images/icons/modal_success.svg';
export { default as IC_GRAPHS } from './images/icons/graphs.svg';
export { default as IC_TODAY_CALENDAR } from './images/icons/today_calendar.svg';
export { default as IC_RESET } from './images/icons/reset_button.svg';

export { default as IC_INLINE_EDIT } from './images/icons/inline_edit.svg';
export { default as IC_TRASH2 } from './images/icons/trash.svg';
export { default as IC_PUBLISH } from './images/icons/publish.svg';
export { default as IC_DELETE_CIRCLE } from './images/icons/delete_warning_modal.svg';
export { default as IC_ERROR_CIRCLE } from './images/icons/error_modal.svg';
export { default as IC_WARNING_CIRCLE } from './images/icons/warning_modal.svg';
export { default as IC_CONFIRM_CIRCLE } from './images/icons/confirm_modal.svg';
export { default as IC_WARNING_INFO_CIRCLE } from './images/icons/warning_orange.svg';
export { default as IC_WARNING_INFO_CIRCLE_FILL } from './images/icons/warning_orange_fill.svg';
export { default as IC_EDIT } from './images/icons/edit 2.svg';
export { default as IC_VIEW } from './images/icons/view_captable.svg';

export { default as IC_PIN } from './images/icons/pin_table.svg';
export { default as IC_CAPTABLE_ARROW_LEFT } from './images/icons/captable_arrow_left.svg';
export { default as IC_CAPTABLE_ARROW_RIGHT } from './images/icons/captable_arrow_right.svg';
export { default as IC_USER_CIRCLE } from './images/icons/user_circle.svg';
export { default as IC_PLUS_WHITE2 } from './images/icons/plus_white.svg';
export { default as IC_PLUS_PURPLE } from './images/icons/plus_purple.svg';
export { default as IC_REFRESH } from './images/icons/button_refresh.svg';
export { default as IC_REFRESH_PURPLE } from './images/icons/button_refresh_purple.svg';
export { default as IC_REFRESH_WHITE } from './images/icons/button_refresh_white.svg';
export { default as IC_LOOKUP } from './images/icons/lookup.svg';
export { default as IC_INFO_ORANGE } from './images/icons/info_orange.svg';
export { default as IC_DOTS_MENU } from './images/icons/dots_menu.svg';
export { default as IC_EDIT_GRAY } from './images/icons/edit_gray.svg';
export { default as IC_SAVE_AS } from './images/icons/save_as.svg';
export { default as IC_DISCARD_TABLE } from './images/icons/discard_table.svg';

export { default as IMG_HEADER } from './images/other/header-bg.svg';
export { default as IMG_HEADER_LOGO } from './images/other/logo.svg';
export { default as IMG_HEADER_LOGO_WHITE } from './images/other/logo-white.svg';
export { default as IC_NOTE } from './images/icons/Group 726.svg';
export { default as IC_PEN } from './images/icons/Group 705.svg';
export { default as IC_FUNEL } from './images/icons/funnel.svg';
export { default as IC_FUNNEL2 } from './images/icons/funnel2.svg';
export { default as IC_FUNNEL2_PURPLE } from './images/icons/funnel2_purple.svg';
export { default as IC_FUNEL_REMOVE } from './images/icons/Group 227.svg';
export { default as IC_CHECK } from './images/icons/Group 733.svg';
export { default as IC_CHECK_CIRCLE_PURPLE } from './images/icons/check_circle_purple.svg';
export { default as IC_CHECK_WHITE } from './images/icons/Path 1544.svg';
export { default as IC_PLUS_BLUE } from './images/icons/Group 3759.svg';
export { default as IC_PLUS_RED } from './images/icons/Group 3763.svg';
export { default as IC_PLUS_YELLOW } from './images/icons/Group 3944.svg';
export { default as IC_PLUS_ORANGE } from './images/icons/Group 3945.svg';
export { default as IC_PLUS_ORANGE_SM } from './images/icons/Group 2274.svg';
export { default as IC_PLUS_WHITE } from './images/icons/Group 1598.svg';
export { default as IC_X } from './images/icons/Group 3904.svg';
export { default as IC_X_BLACK } from './images/icons/Group 2281.svg';
export { default as IC_X_RED } from './images/icons/Group 2282.svg';
export { default as IC_X_RED_ALT } from './images/icons/Group 2250.svg';
export { default as IC_X_CANCEL_CIRCLE } from './images/icons/cancel_x.svg';
export { default as IC_PDF } from './images/icons/Group 712.svg';
export { default as IC_PDF_FILLED } from './images/icons/ICON_PDF_FILLED.svg';
export { default as IC_WORD } from './images/icons/Group 765.svg';
export { default as IC_EXCEL } from './images/icons/Group 763.svg';
export { default as IC_EXCEL2 } from './images/icons/excel_file.svg';
export { default as IC_IMAGE } from './images/icons/pic file100.svg';
export { default as IC_USER_THIN } from './images/icons/contributor icon.svg';
export { default as IC_USER_KEY } from './images/icons/owner icon.svg';
export { default as IC_USER } from './images/icons/Group 1861.svg';
export { default as IC_USER_DARK } from './images/icons/Group 721.svg';
export { default as IC_USER_ALT } from './images/icons/Group 2261.svg';
export { default as IC_USER_PROFILE } from './images/icons/user.svg';
export { default as IC_USER_PROFILE_PURPLE } from './images/icons/user_profile_purple.svg';
export { default as IC_COMPANY_PROFILE_PURPLE } from './images/icons/company_circle_purple.svg';
export { default as IC_COMPANY_CIRCLE } from './images/icons/company_circle.svg';
export { default as IC_LANGUAGE } from './images/icons/language.svg';
export { default as IC_GLOBE } from './images/icons/globe.svg';
export { default as IC_ARROW } from './images/icons/arrow.svg';
export { default as IC_BILLING } from './images/icons/Group 4274.svg';

export { default as IC_USERS } from './images/icons/Group 1859.svg';
export { default as IC_USERS_ALT } from './images/icons/Group 2260.svg';
export { default as IC_View_All } from './images/icons/view all100.svg';
export { default as IC_No_Access } from './images/icons/no access100.svg';

export { default as IC_SIGN } from './images/icons/Group 1864.svg';
export { default as IC_SIGN_ALT } from './images/icons/Group 2262.svg';
export { default as IC_SIGN_PAPER } from './images/icons/Group 1971.svg';
export { default as IC_SIGN_PAPER_ALT } from './images/icons/Group 2265.svg';
export { default as IC_FOLDER_OPEN } from './images/icons/Group 1929.svg';
export { default as IC_FOLDER_CLOSED } from './images/icons/Group 2000.svg';
export { default as IC_DOTS } from './images/icons/Group 1936.svg';
export { default as IC_DOTS_DISABLED } from './images/icons/Group 1855.svg';
export { default as IC_ROOM_USER } from './images/icons/Group 1886.svg';
export { default as IC_ROOM_USER_ALT } from './images/icons/Group 2231.svg';
export { default as IC_ROOM_COMPANY } from './images/icons/Group 1894.svg';
export { default as IC_ROOM_COMPANY_ALT } from './images/icons/Group 2232.svg';
export { default as IC_ENVELOP_BLOCK } from './images/icons/Group 710.svg';
export { default as IC_PLANE } from './images/icons/Group 707.svg';
export { default as IC_PLANE_ALT } from './images/icons/Group 2263.svg';
export { default as IC_EXIT } from './images/icons/Group 739.svg';
export { default as IC_EXIT_DARK } from './images/icons/exit-black.svg';
export { default as IC_EXIT_ALT } from './images/icons/Group 2264.svg';
export { default as IC_UNSORTED } from './images/icons/Group 1949.svg';
export { default as IC_SORT_ASC } from './images/icons/Group 1974.svg';
export { default as IC_SORT_DESC } from './images/icons/Group 1973.svg';
export { default as IC_MAGNIFY_GLASS } from './images/icons/Path 766.svg';
export { default as IC_QUESTION_MARK } from './images/icons/question_mark_grey.svg';
export { default as IC_QUESTION_MARK_ORANGE } from './images/icons/question_mark_orange.svg';
export { default as IC_QUESTION_MARK_ORANGE_FILL } from './images/icons/question_mark_orange_fill.svg';
export { default as IC_QUESTION_MARK_DARK } from './images/icons/question_mark_dark.svg';

export { default as IC_TRASH } from './images/icons/Group 1963.svg';
export { default as IC_TRASH_PURPLE } from './images/icons/trash-purple.svg';
export { default as IC_LOG } from './images/icons/Group 1964.svg';
export { default as IC_FILE_VERSIONS } from './images/icons/Group 1965.svg';
export { default as IC_DUPLICATE } from './images/icons/Group 1966.svg';
export { default as IC_MOVE } from './images/icons/Group 1967.svg';
export { default as IC_SEND } from './images/icons/Group 1968.svg';
export { default as IC_OPEN } from './images/icons/view icon.svg';
export { default as IC_DOWNLOAD } from './images/icons/download icon.svg';
export { default as IC_DOWNLOAD_CLOUD } from './images/icons/ICON_DOWNLOAD_CLOUD.svg';
export { default as IC_BELL } from './images/icons/Group 756.svg';
export { default as IC_GEAR } from './images/icons/Group 1911.svg';
export { default as IC_CALENDAR } from './images/icons/Group 724.svg';
export { default as IC_CALENDAR_WHITE } from './images/icons/calendar_white.svg';
export { default as IC_CALENDAR_PURPLE } from './images/icons/calendar_purple.svg';
export { default as IC_INFO } from './images/icons/Group 1918.svg';
export { default as IC_UPLOAD } from './images/icons/Group 2190.svg';
export { default as IC_UPLOAD_ALT } from './images/icons/Group 2239.svg';
export { default as IC_UPLOAD_ALT2 } from './images/icons/Group 85.svg';
export { default as IC_UPLOAD_YELLOW } from './images/icons/Group 3305.svg';
export { default as IC_LEGAL_ENTITY } from './images/icons/Group 2222.svg';
export { default as IC_LEGAL_ENTITY_DARK } from './images/icons/Group 728.svg';
export { default as IC_PERSON } from './images/icons/Group 2226.svg';
export { default as IC_PEN_PURPLE } from './images/icons/pen-purple.svg';
export { default as IC_PEN_ORANGE } from './images/icons/Group 2240.svg';
export { default as IC_PEN_ORANGE_ALT } from './images/icons/Group 2234.svg';
export { default as IC_PEN_ORANGE_FAT } from './images/icons/Group 240.svg';
export { default as IC_SCROLL_LEFT } from './images/icons/scroll-left.png';
export { default as IC_SCROLL_RIGHT } from './images/icons/scroll-right.png';
export { default as IC_SCROLL_LEFT_ALT } from './images/icons/scroll-left-alt.png';
export { default as IC_SCROLL_RIGHT_ALT } from './images/icons/scroll-right-alt.png';
export { default as IC_CapTable_Split } from './images/icons/split100.svg';

export { default as IC_ASTRISK } from './images/icons/astrisk.svg';
export { default as IC_BANK } from './images/icons/bank.svg';
export { default as IC_DB } from './images/icons/db.svg';
export { default as IC_SPLIT } from './images/icons/split.svg';
export { default as IC_TIMELINE_FLAG } from './images/icons/timeline-flag.svg';
export { default as IC_TIMELINE_OP } from './images/icons/timeline-op.svg';
export { default as IC_TIMELINE_SELL } from './images/icons/timeline-sell.svg';
export { default as IC_TIMELINE_SPLIT } from './images/icons/timeline-split.svg';
export { default as IC_WARNING } from './images/icons/warning-triangle.svg';
export { default as IC_WARNING_WHITE } from './images/icons/warning-white.svg';
export { default as IC_WARNING_ALT } from './images/icons/warning-alt.svg';
export { default as IC_CHEVRON_UP } from './images/icons/chevron-up.svg';
export { default as IC_CHEVRON_DOWN } from './images/icons/chevron-down.svg';
export { default as IC_CHEVRON_RIGHT } from './images/icons/chevron-right.svg';
export { default as IC_CHEVRON_LEFT } from './images/icons/chevron-left.svg';

export { default as IC_User_Login } from './images/login/userIcon.svg';

export { default as IC_LIGHTBULB } from './images/icons/lightbulb.svg';
export { default as IC_MEGAPHONE } from './images/icons/megaphone.svg';
export { default as IC_EYE } from './images/icons/Group 682.svg';
export { default as IC_EYE2 } from './images/icons/eyes_view.svg';
export { default as IC_EYE_PURPLE } from './images/icons/eye-purple.svg';
export { default as IC_EYE_ORANGE } from './images/icons/Group 239.svg';
export { default as IC_EYE_DARK } from './images/icons/eye_dark.svg';
export { default as IC_EXPAND } from './images/icons/Group 222.svg';
export { default as IC_CONTRACT } from './images/icons/Group 238.svg';

export { default as IC_SHAREHOLDERS } from './images/icons/Group 87.svg';
export { default as IC_SHAREHOLDERS_ALT } from './images/icons/Group 100.svg';
export { default as IC_EDITORS } from './images/icons/Group 82.svg';
export { default as IC_EDITORS_ALT } from './images/icons/Group 94.svg';
export { default as IC_PENDINGINVITES } from './images/icons/Group 78.svg';
export { default as IC_PENDINGINVITES_ALT } from './images/icons/Group 90.svg';
export { default as IC_TOTALUSERS } from './images/icons/Group 88.svg';
export { default as IC_TOTALUSERS_ALT } from './images/icons/Group 95.svg';

export { default as IC_DASH_COMPANY } from './images/icons/company-profile.svg';
export { default as IC_DASH_CAPTABLE } from './images/icons/captable.svg';
export { default as IC_DASH_DATAROOM } from './images/icons/dataroom.svg';
export { default as IC_DASH_OPTIONS } from './images/icons/options.svg';

export { default as IC_IC_CONVERTIBLE_LOAN } from './images/icons/ic-convertible-loan.svg';
export { default as IC_SAFE } from './images/icons/ic-safe.svg';

export { default as IC_SUCCESS } from './images/icons/success.svg';
export { default as IC_FAIL } from './images/icons/fail.svg';
export { default as IC_IMPORT_EXCEL } from './images/icons/import-excel.svg';

export { default as IMG_NO_PIC } from './images/other/not_found2.jpg';
export { default as IC_SINATURE } from './images/icons/signature.svg';
export { default as IC_UNDERLINE } from './images/icons/underline.svg';
export { default as IC_TEXTBOX } from './images/icons/textbox.svg';
export { default as IC_EXCEL_WORNNING } from './images/icons/Group 1725.svg';
export { default as IC_WORNNING } from './images/icons/Group 1744.svg';
export { default as IC_EDIT_PEN } from './images/icons/icon-pencil-edit.svg';
export { default as IC_COMPANY } from './images/icons/Group 4186.svg';
export { default as IC_ADMIN } from './images/icons/Group 4147.svg';
export { default as IC_MAIL } from './images/icons/Group 4155.svg';
export { default as IC_PHONE } from './images/icons/Group 4165.svg';
export { default as IM_CREDIT_CARD } from './images/other/cradit_card.svg';
export { default as IMG_PCI_CERTIFIED } from './images/other/pciDss.png';
export { default as IC_TRASH_BIN } from './images/icons/icon_trash.svg';
export { default as IC_EYE_PR } from './images/icons/eyePrCancelIcon.svg';
export { default as IC_EYE_PR_CANCEL } from './images/icons/eyePrIcon.svg';
export { default as IC_CLOUD_PURPLE } from './images/icons/icoCloudPurple.svg';
export { default as IC_CLOUD_BLACK } from './images/icons/icoCloudBlack.svg';
export { default as IC_COLOR_PEOPLE } from './images/icons/Group 2626.svg';
export { default as IC_COLOR_DOOR } from './images/icons/Group 4447.svg';
export { default as IC_NOTE_PURPLE } from './images/icons/note.svg';
export { default as IC_HISTORY } from './images/icons/ic-history.png';

export { default as IMG_LOGO_NAME } from './images/login/LogoName.svg';
export { default as IC_LIST_ITEM } from './images/icons/ListItemIco.svg';
export { default as IMG_INVERTED } from './images/login/Inverted.svg';
export { default as IMG_G2 } from './images/login/G2.svg';
export { default as IC_REG_PROG_1 } from './images/login/RegProgress1.svg';
export { default as IC_REG_PROG_2 } from './images/login/RegProgress2.svg';
export { default as IC_REG_PROG_3 } from './images/login/RegProgress3.svg';
export { default as IC_G_GOOGLE } from './images/login/G-google.svg';
export { default as IMG_VERIFY_MAIL } from './images/login/verifyMail.svg';
export { default as IMG_LOGIN_LOCK } from './images/login/loginLockIcon.png';
export { default as IMG_REG_COMLETE_MOBILE } from './images/login/helloRegUser.png';
export { default as IMG_RESET_PASSWORD } from './images/login/reset-password.png';
export { default as IMG_PASSWORD_CHANGED } from './images/login/passwordChanged.png';
export { default as IC_PASSWORD_INPUT_CLOSED_EYE } from './images/login/closed-eye.svg';
export { default as IC_PASSWORD_INPUT_OPENED_EYE } from './images/login/opened-eye.svg';
export { default as IMG_OPTION_REG_INTRO } from './images/other/options-reg-intro.png';
export { default as IC_CANCEL_SQUARE } from './images/icons/cancelSquare.svg';
export { default as IMG_STAKEHOLDERS_HISTORY } from './images/other/dashboard-stakeholders-history.png';
export { default as IC_UPDATES_CREATE } from './images/icons/dashboard-updates-add-share-class.svg';
export { default as IC_UPDATES_GRANT } from './images/icons/dashboard-updates-add-grants.svg';
export { default as IC_UPDATES_PUBLISH } from './images/icons/dashboard-updates-published.svg';
export { default as IC_UPDATES_DELETE } from './images/icons/dashboard-updates-delete.svg';

export { default as IMG_ACTIVE_BENEFICIARIES } from './images/other/dashboard-equity-active-beneficiaries.png';
export { default as IC_ACTIVE_BENEFICIARIES } from './images/icons/active_beneficiaries.svg';

export { default as IC_EQUITY_CONNECTOR } from './images/icons/dashboard-equity-connector.svg';
export { default as IC_DASHBOARD_ARROW } from './images/icons/dashboard-arrow.svg';
export { default as IMG_PAYMENT_CHECKOUT_ALTSHARE_LOGO } from './images/other/payment-checkout-altshare-logo.png';
export { default as IC_PAYMENT_PLAN_CHECK } from './images/icons/payment-plan-check.svg';
export { default as STAKEHOLDERS } from './images/icons/stakeholders.svg';
export { default as IC_DASHBOARD_LOCK_CARD } from './images/icons/dashboard-lock-card.svg';
export { default as IC_BRAND_LOGO_BLACK } from './images/icons/brand-logo-black.svg';

export { default as IC_ADDITIONAL_VIEWS } from './images/icons/additional_views.svg';
export { default as IC_QA_CANCEL } from './images/icons/cancel.svg';
export { default as IC_QA_EXERCISE } from './images/icons/exercise.svg';
export { default as IC_QA_SELL } from './images/icons/sell.svg';
export { default as IC_TERMINATE } from './images/icons/fructure.svg';
export { default as IC_INVITE } from './images/icons/airplane.svg';
export { default as IMG_STRIPE_BADGE } from './images/other/stripe-badge.png';
export { default as IMG_STRIPE_PCI_BADGE } from './images/other/payment-pci-badge.png';
export { default as IMG_STRIPE_CARDS_TYPE } from './images/other/stripe-cards-type.svg';
export { default as IC_GRANTS } from './images/icons/options_granted.svg';
export { default as IC_BENEFICIARIES } from './images/icons/options_beneficiaries.svg';
export { default as IC_EXERCISED } from './images/icons/options_exercised.svg';
export { default as IC_LIMITATION } from './images/icons/limitation.svg';
export { default as IC_LIMITATION_ADMIN } from './images/icons/limitation-admin.svg';
export { default as IC_CAPS_LOCK } from './images/icons/caps-lock.svg';

export { default as IC_TABLE_MENU_EYE } from './images/icons/table_menu_eye.svg';
export { default as IC_MENU_HORIZONTAL } from './images/icons/menu_horizontal.svg';
export { default as IC_MENU_HORIZONTAL_HOVER } from './images/icons/menu_horizontal_hover.svg';
export { default as IC_MENU_VERTICAL } from './images/icons/menu_vertical.svg';
export { default as IC_MENU_VERTICAL_HOVER } from './images/icons/menu_vertical_hover.svg';
export { default as IC_BILLING_PDF } from './images/icons/billing-pdf.svg';
export { default as IC_FUNDING_CREATE_CAP_TABLE } from './images/icons/funding_create_cap_table.svg';

export { default as IC_WATERFALL_ANALYSIS } from './images/icons/analysis_icon.svg';
export { default as IC_WATERFALL_SUMMARY } from './images/icons/summary.svg';
export { default as IC_WATERFALL_SUMMARY_PURPLE } from './images/icons/summary_purple.svg';
export { default as IC_TUTORIAL } from './images/icons/tutorial.svg';

export { default as IC_CAPTABLE_LOGO } from './images/icons/captable_logo.svg';
export { default as IC_CAPTABLE_LOGO_INSTANT } from './images/icons/captable_logo_instant.svg';
export { default as IC_PLAY_TUTORIAL_LOGO } from './images/icons/play_tutorial_logo.svg';
export { default as IC_PLAY_WHITE } from './images/icons/play_white.svg';
export { default as IC_ADD_FILE } from './images/icons/add_file.svg';
export { default as IC_SHARE } from './images/icons/share.svg';

// Trustee

export { default as IC_SUBMIT } from './images/icons/submit.svg';
export { default as IC_REPORT } from './images/icons/report.svg';
export { default as IC_FORM } from './images/icons/form.svg';
export { default as IC_DOCUMENT } from './images/icons/document.svg';
export { default as IC_EQUITY_SETTINGS } from './images/icons/equitySettings.svg';
export { default as IC_EQUITY_SETTINGS_BLACK } from './images/icons/equitySettings_black.svg';
export { default as IC_CHANGE_ARROWS } from './images/icons/taki_reverse.svg';
export { default as IC_EXCEL_GREEN } from './images/icons/xls_green.svg';
export { default as IC_DROP_FILE } from './images/icons/drop_file.svg';
export { default as IC_SUBMIT_FILE } from './images/icons/submit_file.svg';
export { default as IC_DOCUMENT_PEN } from './images/icons/document_pen.svg';
export { default as IC_DOCUMENT_PDF } from './images/icons/pdf_red.svg';
export { default as IC_FORMS } from './images/icons/forms.svg';
export { default as IC_CANCEL_ROUND } from './images/icons/cancel_round.svg';

// export { default as IC_EQUITY_SETTINGS_BLACK } from "./images/icons/equitySettings_black.svg";
// export { default as IC_DROP_FILE } from "./images/icons/drop_file.svg";
// export { default as IC_SUBMIT_FILE } from "./images/icons/submit_file.svg";
// export { default as IC_DOCUMENT_PEN } from "./images/icons/document_pen.svg";
// export { default as IC_DOCUMENT_PDF } from "./images/icons/pdf_red.svg";
// export { default as IC_FORMS } from "./images/icons/forms.svg";

export { default as IC_NEW_ERPORT } from './images/icons/new_report.svg';
export { default as IC_SETTINGS } from './images/icons/settings.svg';
export { default as IC_DATA_MANAGEMENT } from './images/icons/data_management.svg';
export { default as IC_REPORT_SETTINGS } from './images/icons/report_settings.svg';
export { default as IC_REPORT_SETTINGS_PURPLE } from './images/icons/report_settings_purple.svg';
export { default as IC_EXPENSING_LOGO } from './images/icons/expensing_logo.svg';
export { default as IC_REPORTS_CIRCLE } from './images/icons/reports_dashboard.svg';
export { default as IC_EXPENSING_SETTINGS } from './images/icons/expensing_settings.svg';
export { default as IC_ARROW_BACK } from './images/icons/arrow_back.svg';
export { default as IC_TABLE } from './images/icons/table.svg';
export { default as HO_TO_FIRST_IMG } from './images/other/howToFirstImg.png';
export { default as HO_TO_SEC_IMG } from './images/other/howToSecImg.png';
export { default as HO_TO_3_IMG } from './images/other/howTo3Img.png';



export { default as IC_INACTIVE } from './images/icons/inactive.svg';
// export { default as IC_EXCEL_GREEN } from "./images/icons/xls_green.svg";

export { default as IC_REORDER } from './images/icons/reorder.svg';

export { default as IC_EYE_ACTION } from './images/icons/eye_action.svg';
export { default as IC_DOWNLOAD_ACTION } from './images/icons/download_action.svg';
export { default as IC_EDIT_ACTION } from './images/icons/edit_action.svg';
export { default as IC_TRASH_ACTION } from './images/icons/trash_action.svg';
export { default as IC_DOC_WORD } from './images/icons/doc_word.svg';
export { default as IC_DOC_PDF } from './images/icons/doc_pdf.svg';
export { default as IC_MARK_RIGHT } from './images/icons/mark_right.svg';

export { default as IC_PREVIEW_USER } from './images/icons/preview_user.svg';
export { default as IC_PREVIEW_LAST_UPDATE } from './images/icons/preview_last_update.svg';
export { default as IC_PREVIEW_RULE } from './images/icons/preview_rule.svg';
export { default as IC_PREVIEW_TYPE } from './images/icons/preview_type.svg';
export { default as IC_PREVIEW_DATE } from './images/icons/preview_date.svg';
export { default as IC_PREVIEW_DATE_COMPLETE } from './images/icons/preview_date_complete.svg';
export { default as IC_PREVIEW_PENDING } from './images/icons/preview_pending.svg';
export { default as IC_PREVIEW_SIGNATURE } from './images/icons/preview_signature.svg';
export { default as IC_SIGN_ACTION } from './images/icons/sign_action.svg';
export { default as IC_LETTER_READY } from './images/icons/grant_letter_ready.svg';
export { default as IC_LETTER_SENT } from './images/icons/grant_letter_sent.svg';
export { default as IC_LETTER_CANCELLED } from './images/icons/grant_letter_cancelled.svg';
export { default as IC_LETTER_SIGNED } from './images/icons/grant_letter_signed.svg';
export { default as IC_SEND_FOR_SIGNATURE } from './images/icons/send_for_signature.svg';
export { default as IC_SEND_FOR_SIGNATURE_ACTIVE } from './images/icons/send_for_signature_active.svg';
// Trustee

// export { default as IC_SUBMIT } from "./images/icons/submit.svg";
// export { default as IC_REPORT } from "./images/icons/report.svg";
// export { default as IC_FORM } from "./images/icons/form.svg";
// export { default as IC_DOCUMENT } from "./images/icons/document.svg";
// export { default as IC_EQUITY_SETTINGS } from "./images/icons/equitySettings.svg";
// export { default as IC_CHANGE_ARROWS } from "./images/icons/taki_reverse.svg";
export { default as IC_ADD_CERTIFICATE } from './images/icons/add_certificate.svg';

export { default as IC_PREVIEW_BACKGROUND_EXPENSING } from './images/icons/preview_background_expensing.svg';
export { default as IC_PREVIEW_BACKGROUND_WATERFALL } from './images/icons/preview_background_waterfall.svg';
export { default as IC_PREVIEW_BACKGROUND_VALUATION } from './images/icons/preview_background_valuation.svg';
export { default as IC_WATERFALL_LOGO } from './images/icons/waterfall_logo.svg';
export { default as IC_VALUATION_LOGO } from './images/icons/valuation_logo.svg';
export { default as IC_EXPORT_TO_EXCEL_BUTTON } from './images/icons/export_to_excel_button.svg';
export { default as IC_FILTER_BUTTON } from './images/icons/filter_button.svg';
export { default as IC_FILTER_BUTTON_CLICKED } from './images/icons/filter_button_clicked.svg';
export { default as IC_FILTER } from './images/icons/filter.svg';
export { default as IC_FILTER_CLICKED } from './images/icons/filter_clicked.svg';
export { default as IC_CLOSED } from './images/icons/icon_close.svg';